import React from 'react'
import {Link } from 'gatsby'


export default function PreviousNext({baseURL, previous, next}) {

    return (
        <div className="container py-3 mt-3">
            <div className="row">
                <div className="col-md-6 text-center">
                    <span className="float-md-left">
                    {previous !== null &&
                      <Link className="btn btn-outline-success btn-sm" to={baseURL+previous.frontmatter.slug}>
                          {"< " + previous.frontmatter.title}
                      </Link>
                    }
                    </span>
                </div>
                <div className="col-md-6 text-center">
                    <span className="float-md-right">
                    {next !== null &&
                      <Link className="btn btn-outline-success btn-sm" to={baseURL+next.frontmatter.slug}>
                          {next.frontmatter.title + " >" }
                      </Link>
                    }
                    </span>
                </div>
            </div>
        </div>
    )
}