import React from 'react'
import Layout from '../components/Layout'
import * as styles  from '../styles/project-details.module.css'
import { graphql } from 'gatsby'
import Eleave from '../components/projects/laravel/eleave'
import { Row, Col}  from 'react-bootstrap'
import PreviousNext from '../components/Previous_Next'

import Seo from '../components/SEO'

export default function LaravelEleaveProjectDetails({data, pageContext }) {

  const { html } = data.markdownRemark
  const { title} = data.markdownRemark.frontmatter

  const { next, previous } = pageContext

    return (

      <Layout>
          <Seo
            title={title}
            description="e-Leave application builds on Laravel, a PHP framework."
          />
            <Row>
                <Col xs={12} md={3}>
                    <Eleave></Eleave>
                </Col>
                <Col xs={12} md={9}>
                <div className={styles.details}>
                    <h1>{title}</h1>
                    <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
                    <PreviousNext baseURL={"/projects/laravel/eleave/"} previous={previous} next={next}></PreviousNext>
                </div>
                </Col>
            </Row>
        </Layout>
    )
}

export const query = graphql`
  query ProjectLaravelELeaveDetails($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        slug
        sequence
      }
    }
  }
`
